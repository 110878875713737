<template>
  <div id="pat_appointments" class="tab-pane fade show active">
    <div class="card card-table mb-0">
      <div class="card-body">
        <div class="align-container">
          <div class="left-align">
            <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLeft"></i>
          </div>
          <div class="right-align">
            <i class="fa fa-long-arrow-right" aria-hidden="true" @click="scrollTableRight"></i>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div></div>
          <label class="custom_check" style="text-align: right;">
            <input
              type="checkbox"
              id="active-appointments"
              v-model="isActive"
              @change="toggleActiveStatus"
              checked
            />
            <span class="checkmark"></span>
            Include Cancelled
          </label>
        </div>
        <div class="table-responsive" ref="tableContainer">
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <!-- <th>Doctor</th> -->
                <th>Appt Date</th>
                <th>Appt Time</th>
                <th>Booking Date</th>
                <th>Clinic</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in this.filteredAppointments" :key="item.slno">
                <!-- <td>
                  <h2 class="table-avatar">
                    <router-link to="doctor-profile" class="avatar avatar-sm me-2">
                      img
                        class="avatar-img rounded-circle"
                        :src="require(`@/assets/img/doctors/${item.Image}`)"
                        alt="User Image"
                      /
                    </router-link>

                    <router-link to="doctor-profile">Dr. {{ item.doctorname }}</router-link>
                  </h2>
                </td> -->
                <td>{{ formatDate(item.appointmentdate) }}</td>
                <td>{{ formatTime(item.appointmenttime) }}</td>

                <td>{{ formatDate(item.createddate) }}</td>
                <td>{{ item.clinicname }}</td>
                <td>
                  <span :class="getStatusColor(item.appointmentstatus)">{{ item.appointmentstatus }}</span>
                </td>
                <td>
                  <div class="table-action">
                    <router-link to="edit-prescription" :class="item.class2">
                      <i :class="item.class1"></i>
                      {{ item.edit }}
                    </router-link>
                    <a href="javascript:void(0);" :class="item.class3">
                      <i :class="item.class4"></i>
                      {{ item.reschedule }}
                    </a>
                    <a
                      v-if="item.appointmentstatus === 'Upcoming'"
                      href="javascript:void(0);"
                      class="cancel-button"
                      @click="cancelAppointment(item)"
                    >
                      <i class="fas fa-times-circle me-1"></i> Cancel
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  props: {
    patientid: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dappointment: [],
      isActive: true,
      filteredAppointments: []
    };
  },
  beforeMount() {
    this.fetchAppointments();
  },
  methods: {
    async fetchAppointments() {
      this.doctorid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(
          `patients/${this.patientid}/appointmentslist/${this.doctorid}`
        );
        this.dappointment = response.data.appointmentlist;
        this.filterAppointments();
        //console.log(this.dappointment);
      } catch (error) {
        console.error("Error fetching appointments:", error);
      }
    },

    filterAppointments() {
      if (!this.isActive) {
        this.filteredAppointments = this.dappointment.filter(
          appointment =>
            appointment.appointmentstatus == "Visited" ||
            appointment.appointmentstatus == "Upcoming" ||
            appointment.appointmentstatus == "Absent" ||
            appointment.appointmentstatus == "Confirmed"
        );
      } else {
        this.filteredAppointments = this.dappointment;
      }
    },
    toggleActiveStatus() {
      this.filterAppointments();
    },
    formatDate(dateString) {
      const [day, month, year] = dateString.split('/');
      const date = new Date(`${year}-${month}-${day}`);
      return new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }).format(date);
    },
    formatTime(time) {
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },
    cancelAppointment(appointment) {
      //console.log(appointment);
      const {
        slno,
        patientname,
        appointmentby,
        appointmentdate,
        appointmenttime
      } = appointment;

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the appointment of ${patientname} on ${this.formatDate(
          appointmentdate
        )} at ${this.formatTime(appointmenttime)}?`,
        input: "text",
        showCancelButton: false,
        inputLabel: "Reason for cancellation",
        inputPlaceholder: "Enter reason here...",
        icon: "warning",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Confirm Cancel",
        customClass: {
          popup: "swal-popup-custom",
          closeButton: "swal-close-button-custom"
        },
        showCloseButton: true,
        closeButtonHtml: "&times;"
      }).then(result => {
        if (result.isConfirmed) {
          const cancelReason = result.value;

          const cancelApiUrl = `/patients/${appointmentby}/appointments/${slno}`;
          axios
            .put(cancelApiUrl, { cancelreason: cancelReason })
            .then(response => {
              console.log("Appointment cancelled successfully"); //, response.data);

              this.fetchAppointments();
            })
            .catch(error => {
              console.error("Error cancelling appointment", error);
            });
        }
      });
    },
    getStatusColor(appointmentStatus) {
      if (appointmentStatus === "Cancelled") {
        return "status-cancelled";
      } else if (appointmentStatus === "Visited") {
        return "status-completed";
      } else if (appointmentStatus === "Confirmed") {
        return "status-confirmed";
      } else if (appointmentStatus === "Upcoming") {
        return "status-upcoming";
      } else if (appointmentStatus === "Absent") {
        return "status-absent";
      }
    },
    scrollTableRight() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },

    scrollTableLeft() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style>
.left-align {
  text-align: left;
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.align-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-align {
  text-align: right;
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}
.cancel-button {
  background-color: rgb(243, 41, 41);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}
.status-cancelled {
  color: red;
}

.status-completed {
  color: blue;
}

.status-confirmed {
  color: green;
}

.status-upcoming {
  color: darkcyan;
}
.status-absent {
  color: orangered;
}
</style>