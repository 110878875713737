
<template>
  <div class="main-wrapper">
    <layoutheader v-if="!showModal" />
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar />
          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <b-alert v-model="showErrorAlert" variant="danger" dismissible>{{ error }}</b-alert>
                <b-alert v-model="showSuccessAlert" variant="success" dismissible>{{ success }}</b-alert>
                <h4 class="card-title d-flex justify-content-between">
                  Clinic Settings
                  <button class="main-btn" @click="openModal()">Add Clinic</button>
                </h4>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>Clinic</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="clinic in clinicList" :key="clinic.clinicid">
                        <td>{{ clinic.clinicname }}</td>
                        <td>
                          <a
                            href="javascript:void(0);"
                            @click="fetchClinicSettings(clinic.clinicid, clinic.clinicname)"
                            class="btn btn-sm bg-info-light me-2"
                          >
                            <i class="far fa-eye"></i> View
                          </a>
                        </td>
                      </tr>
                      <tr v-if="clinics.length === 0">
                        <td colspan="2">No clinics available</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add/Edit Modal -->
    <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <div class="modal-header">
          <h3>{{ editing ? "Edit Clinic Settings" : "Add Clinic Settings" }}</h3>
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="saveSettings">
            <div v-if="!editing" class="mb-3">
              <label for="clinicDropdown" class="form-label">Select Clinic:</label>
              <select id="clinicDropdown" class="form-select" v-model="selectedClinicId" required>
                <option value disabled>Select a clinic</option>
                <option
                  v-for="clinic in availableClinics"
                  :key="clinic.clinicid"
                  :value="clinic.clinicid"
                >{{ clinic.clinicname }}</option>
              </select>
            </div>
            <div v-else class="mb-3">
              <label for="clinicDropdown" class="form-label">Selected Clinic:</label>
              <div class="form-control" readonly>{{ selectedClinic }}</div>
            </div>
            <div class="mb-3">
              <label for="headerImage" class="form-label">Clinic Logo Image:</label>
              <img
                v-if="headerImage"
                :src="headerImagePreview"
                alt="Header Preview"
                class="img-thumbnail mb-2"
              />
              <input
                id="headerImage"
                type="file"
                class="form-control"
                @change="onFileSelect('headerImage', $event)"
              />
            </div>
            <div class="mb-3">
              <label for="footerImage" class="form-label">Clinic Footer Image:</label>
              <img
                v-if="footerImage"
                :src="footerImagePreview"
                alt="Footer Preview"
                class="img-thumbnail mb-2"
              />
              <input
                id="footerImage"
                type="file"
                class="form-control"
                accept="image/*"
                @change="onFileSelect('footerImage', $event)"
              />
            </div>
            <div class="mb-3">
              <label for="docSignature" class="form-label">Doctor Signature:</label>
              <img
                v-if="docSignature"
                :src="docSignaturePreview"
                alt="Signature Preview"
                class="img-thumbnail mb-2"
              />
              <input
                id="docSignature"
                type="file"
                class="form-control"
                accept="image/*"
                @change="onFileSelect('docSignature', $event)"
              />
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="confirm-btn"
            :disabled="loading || (!headeruploaded && !footeruploaded && !signatureruploaded)"
            @click="saveSettings"
          >
            <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
            Save
          </button>

          <button class="back-btn" @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      title: "Clinic Settings",
      text: "Home",
      text1: "Clinic Settings",
      clinics: [],
      availableClinics: [],
      showModal: false,
      editing: false,
      currentClinic: null,
      headerImage: null,
      footerImage: null,
      docSignature: null,
      headerImagePreview: null,
      footerImagePreview: null,
      docSignaturePreview: null,
      loading: false,
      selectedClinicId: '',
      selectedClinic: "",
      showErrorAlert: false,
      showSuccessAlert: false,
      error: "",
      clinicSettings: null,
      clinicList: [],
      success: "",
      headeruploaded: false,
      footeruploaded: false,
      signatureruploaded: false
    };
  },
  methods: {
    async fetchClinicSettingsList() {
      await this.fetchClinics();
      try {
        const doctorId = sessionStorage.getItem("userdbid");

        const response = await axios.get(`/doctors/${doctorId}/clinicids`);
        const clinicIds = response.data;
        this.clinicList = this.clinics
          .filter(clinic => clinicIds.includes(clinic.clinicid))
          .map(clinic => ({
            clinicid: clinic.clinicid,
            clinicname: clinic.clinicname
          }));
           // Update availableClinics to exclude clinics in clinicList
    const clinicListIds = this.clinicList.map(clinic => clinic.clinicid);
    this.availableClinics = this.clinics.filter(
      clinic => !clinicListIds.includes(clinic.clinicid)
    );
      } catch (error) {
        console.error("Error fetching clinic settings list:", error);
      }
    },
    async fetchClinicSettings(clinic_id, clinic_name) {
      try {
        this.showModal = true;
        this.editing = true;
        this.selectedClinic = clinic_name;
        this.selectedClinicId = clinic_id;
        const doctorId = sessionStorage.getItem("userdbid");
        const response = await axios.get(
          `/doctors/${doctorId}/doctorclinic-settings/${clinic_id}`
        );
        this.clinicSettings = response.data;

        this.headerImage = response.data.prescrip_header_image;
        if (this.headerImage) {
          this.headerImagePreview = `data:image/png;base64,${this.headerImage}`;
        }

        this.footerImage = response.data.prescrip_footer_image;
        if (this.footerImage) {
          this.footerImagePreview = `data:image/png;base64,${this.footerImage}`;
        }

        this.docSignature = response.data.docsignature;
        if (this.docSignature) {
          this.docSignaturePreview = `data:image/png;base64,${this.docSignature}`;
        }
      } catch (error) {
        console.error("Error fetching clinic settings:", error);
        this.clinicSettings = null;
      }
    },
    async fetchClinics() {
      try {
        const response = await axios.get(`/doctors/clinichospital`);
        this.clinics = response.data.filter(clinic => clinic.listyn);
        this.availableClinics = this.clinics;
      } catch (err) {}
    },

    async fetchClinicDetails(clinicId) {
      try {
        const doctorId = sessionStorage.getItem("userdbid");
        const response = await axios.get(
          `/doctors/${doctorId}/clinic/${clinicId}`
        );
        const clinic = response.data;
        this.headerImagePreview = clinic.prescrip_header_image;
        this.footerImagePreview = clinic.prescrip_footer_image;
        this.docSignaturePreview = clinic.docsignature;
        this.currentClinic = clinic;
      } catch (err) {
        this.error = "Failed to fetch clinic details.";
        this.showErrorAlert = true;
        setTimeout(() => {
          this.showErrorAlert = false;
        }, 5000);
      }
    },
    openModal(clinic = null) {
      this.showModal = true;

      if (clinic) {
        this.fetchClinicDetails(clinic.clinicid);
      } else {
        this.resetForm();
      }
    },
    resetForm() {
      this.headerImage = null;
      this.footerImage = null;
      this.docSignature = null;
      this.selectedClinicId = '';
      this.headerImagePreview = null;
      this.footerImagePreview = null;
      this.docSignaturePreview = null;
    },
    onFileSelect(type, event) {
      const file = event.target.files[0];
      if (!file) return;

      if (type === "headerImage") {
        this.headeruploaded = true;
        this.headerImage = file;
        this.headerImagePreview = URL.createObjectURL(file);
      } else if (type === "footerImage") {
        this.footeruploaded = true;
        this.footerImage = file;
        this.footerImagePreview = URL.createObjectURL(file);
      } else if (type === "docSignature") {
        this.signatureruploaded = true;
        this.docSignature = file;
        this.docSignaturePreview = URL.createObjectURL(file);
      }
    },
    async saveSettings() {
      this.loading = true;
      try {
        const doctorId = sessionStorage.getItem("userdbid");
        const formData = new FormData();

        if (this.headerImage && this.headeruploaded) {
          formData.append("headerimage", this.headerImage);
        }
        if (this.footerImage && this.footeruploaded) {
          formData.append("footerimage", this.footerImage);
        }
        if (this.docSignature && this.signatureruploaded) {
          formData.append("docsignature", this.docSignature);
        }
        const url = this.editing
          ? `/doctors/${doctorId}/doctorclinic-settings/${this.selectedClinicId}`
          : `/doctors/${doctorId}/doctorclinic-settings/${this.selectedClinicId}`;
        const method = this.editing ? "post" : "post";

        const response = await axios({
          method,
          url,
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data"
          }
        });

        this.success = this.editing
          ? "Clinic settings updated successfully"
          : "Clinic added successfully!";
        this.showSuccessAlert = true;
        setTimeout(() => {
          this.showSuccessAlert = false;
        }, 5000);
        this.fetchClinicSettingsList();
      } catch (error) {
        console.error("Error saving clinic settings:", error);
        this.error = "Error saving clinic settings.";
        this.showErrorAlert = true;
        setTimeout(() => {
          this.showErrorAlert = false;
        }, 5000);
      } finally {
        this.closeModal();
        this.loading = false;
      }
    },
    closeModal() {
      this.showModal = false;
      this.editing = false;
      this.headeruploaded = false;
      this.footeruploaded = false;
      this.signatureruploaded = false;
      this.resetForm();
    }
  },
  beforeMount() {
    this.fetchClinicSettingsList();
  }
};
</script>
<style scoped>
.img-thumbnail {
  max-width: 150px;
  max-height: 100px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}
.modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}
.modal-body {
  margin-bottom: 20px;
}
.modal-footer {
  display: flex;
  justify-content: space-between;
}

.main-btn {
  background-color: #0e82fd;
  border: 1px solid;
  border-radius: 10px;
  color: #fff;
  padding: 5px 10px;
  margin-right: 5px;
  margin-left: 1px;
  font-size: 17px;
}
.main-btn:hover {
  background-color: #ffffff;
  border: 1px solid #0e82fd;
  color: #0e82fd;
}
.confirm-btn {
  background-color: #007bff;
  color: white;
}

.back-btn {
  background-color: #6c757d;
  color: white;
}

.confirm-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
}

.confirm-btn:disabled {
  background-color: #ccc;
}

.back-btn {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
}
</style>