<template>
  <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
    <div class="stickysidebar">
      <!-- Profile Sidebar -->
      <div class="profile-sidebar">
        <div class="widget-profile pro-widget-content">
          <a href="/doctor-dashboard">
          <div class="profile-info-widget">
            <a href="/doctor-dashboard" class="booking-doc-img">
              <img :src="profileImageUrl || defaultImageUrl" alt="User Image" />
            </a>
            <div href="/doctor-dashboard" class="profile-det-info">
              <h3>Dr. {{ doctorDetails.doctorname }}</h3>
              <div class="patient-details">
                <span class="qualification-tooltip"
                  :title="`${doctorDetails.qualifications} ${doctorDetails.specialization}`">{{
                    doctorDetails.qualifications }}</span>
                {{ doctorDetails.specialization }}
              </div>
            </div>
          </div>
        </a>
        </div>
        <div class="dashboard-widget">
          <nav class="dashboard-menu">
            <ul>
              <li :class="{ active: isActive('/doctor-dashboard') }">
                <router-link to="/doctor-dashboard">
                  <i class="fas fa-columns"></i>
                  <span class="ms-1">Dashboard</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/doctors-report') }">
                <router-link to="/doctors-report">
                  <i class="fas fa-file-alt"></i>
                  <span class="ms-1">Reports</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/appointments') }">
                <router-link to="/appointments">
                  <i class="fas fa-calendar-check"></i>
                  <span class="ms-1">Appointments History</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/my-patients') }">
                <router-link to="/my-patients">
                  <i class="fas fa-user-injured"></i>
                  <span class="ms-1">My Patients</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/schedule-timings') }">
                <router-link to="/schedule-timings">
                  <i class="fas fa-hourglass-start"></i>
                  <span class="ms-1">Schedule Timings</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/addappointment') }">
                <router-link to="/addappointment">
                  <i class="fas fa-calendar-plus"></i>
                  <span class="ms-1">Add Appointment</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/time-off') }">
                <router-link to="/time-off">
                  <i class="fas fa-calendar-check"></i>
                  <span class="ms-1">Time-Off</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/available-timings') }">
                <router-link to="/available-timings">
                  <i class="fas fa-edit"></i>
                  <span class="ms-1">Cancel Slots</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/doctor-medicine') }">
                <router-link to="/doctor-medicine">
                  <i class="fas fa-pills"></i>
                  <span class="ms-1">Medicines</span>
                </router-link>
              </li>
              <!--li :class="{ active: isActive('/available-timings') }">
                <router-link to="/available-timings">
                  <i class="fas fa-clock"></i>
                  <span class="ms-1">Available Timings</span>
                </router-link>
              </li-->
              <!--li :class="{ active: isActive('/invoices') }">
                <router-link to="/invoices">
                  <i class="fas fa-file-invoice"></i>
                  <span class="ms-1">Invoices</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/accounts') }">
                <router-link to="/accounts">
                  <i class="fas fa-file-invoice-dollar"></i>
                  <span class="ms-1">Accounts</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/reviews') }">
                <router-link to="/reviews">
                  <i class="fas fa-star"></i>
                  <span class="ms-1">Reviews</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/chat-doctor') }">
                <router-link to="/chat-doctor">
                  <i class="fas fa-comments"></i>
                  <span class="ms-1">Message</span>
                  <small class="unread-msg">23</small>
                </router-link>
              </li-->
                <li :class="{ active: isActive('/doctor-profile-settings') }">
                  <router-link to="/doctor-profile-settings">
                    <i class="fas fa-user-cog"></i>
                    <span class="ms-1">Profile Settings</span>
                  </router-link>
                </li>
                <li :class="{ active: isActive('/doctor-clinic-settings') }">
                  <router-link to="/doctor-clinic-settings">
                    <i class="fas fa-clinic-medical"></i>
                    <span class="ms-1">Clinic Settings</span>
                  </router-link>
              </li>
              <li :class="{ active: isActive('/social-media') }">
                <router-link to="/social-media">
                  <i class="fas fa-share-alt"></i>
                  <span class="ms-1">Social Media</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/doctor-change-password') }">
                <router-link to="/doctor-change-password">
                  <i class="fas fa-lock"></i>
                  <span class="ms-1">Change Password</span>
                </router-link>
              </li>
              <li :class="{ active: isActive('/') }">
                <router-link to @click="signOutUser">
                  <i class="fas fa-sign-out-alt"></i>
                  <span class="ms-1">Logout</span>
                </router-link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <!-- /Profile Sidebar -->
    </div>
  </div>
</template>
<script>
import { signOut } from "aws-amplify/auth";
import axios from "axios";
import defaultImageUrl from "@/assets/img/doctors/doctor-thumb-01.jpg";
import eventBus from "@/eventBus";

export default {
  data() {
    return {
      activeClass: "active",
      doctorDetails: [],
      defaultImageUrl: defaultImageUrl,
      profileImageUrl: ""
    };
  },
  methods: {
    isActive(paths) {
      return paths.includes(this.$route.path);
    },
    signOutUser() {
      signOut()
        .then(data => {
          this.$router.push("/login");
        })
        .catch(err => console.log(err));
    },
    async fetchDoctorProfileImage() {
      const doctorid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(
          `doctors/doctorimg/${doctorid}/download`,
          {
            responseType: "arraybuffer"
          }
        );

        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        this.profileImageUrl = objectURL;
      } catch (error) {
        console.error("Error fetching patient profile image:", error);
      }
    },
    async fetchDoctorDetails() {
      const doctorId = sessionStorage.getItem("userdbid");
      //console.log("doctor id is ", doctorId);
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
        if (!docDetails) {
          const response = await axios.get(`/doctors/${doctorId}`);
          docDetails = response.data;
          sessionStorage.setItem(
            `doctorDetails${this.doctorId}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctorDetails = docDetails;
        //console.log("Doctor Details:", this.doctorDetails);
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    }
  },
  async mounted() {
    await this.fetchDoctorDetails();
    await this.fetchDoctorProfileImage();
    this.$emit('doctor-image', this.profileImageUrl);
    eventBus.on('dimageUploaded', this.fetchDoctorProfileImage);
  },
  beforeUnmount() { 
    console.log("event is removed")
    setTimeout(() => {
      eventBus.off('dimageUploaded', this.handleImageUpload);
    }, 0);
  }

};
</script>
