<template>
  <!-- Footer -->
  <footer class="footer footer-one">
    <div class="footer-top">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-md-4">
            <div class="footer-widget footer-about">
              <div class="footer-logo">
                <img src="@/assets/img/logo.png" alt="logo" />
              </div>
              <div class="footer-about-content">
                <p>
                  YourDoc is a platform which intents to help people like you and me by making healthcare easy to
                  access.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-3 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Start here</h2>
                  <ul>
                    <!--li><router-link to="/search">Search for Doctors</router-link></li>
                    <li><router-link to="/login">Login</router-link></li>
                    <li><router-link to="/register">Register</router-link></li-->
                    <li><router-link to="/doctor-info">For Doctors</router-link></li>
                    <li><router-link to="/faq">FAQ</router-link></li>
                  </ul>
                </div>
              </div>
              <!--div class="col-lg-3 col-md-4">
                <div class="footer-widget footer-menu">
                  <h2 class="footer-title">Specialist</h2>
                  <ul>
                    <li><router-link to="/search?speciality=general">Find a Doctor</router-link></li>
                    <li><router-link to="/search?speciality=cardiologist">Find a Cardiologist</router-link></li>
                    <li><router-link to="/search?speciality=pediatrist">Find a Pediatrist</router-link></li>
                    <li><router-link to="/search?speciality=dentist">Find a Dentist</router-link></li>
                    <li><router-link to="/search?speciality=physiotherapist">Find a Physiotherapist</router-link></li>
                    <li><router-link to="/search?speciality=chiropractor">Find a Chiropractor</router-link></li>
                    <li><router-link to="/search?speciality=psychologist">Find a Psychologist</router-link></li>
                    <li><router-link to="/search?speciality=dietitian">Find a Dietitian</router-link></li>
                    <li><router-link to="/search?speciality=osteopath">Find a Osteopath</router-link></li>
                    <li><router-link to="/search?speciality=dermatologist">Find a Dermatologist</router-link></li>
                    <li><router-link to="/search?speciality=radiologist">Find a Radiologist</router-link></li>
                  </ul>
                </div>
              </div-->
              <div class="col-lg-6 col-md-4">
                <div class="footer-widget footer-contact">
                  <h2 class="footer-title">Contact Us</h2>
                  <div class="footer-contact-info">
                    <div class="footer-address">
                      <p><i class="feather-map-pin"></i> Mangaluru, Karnataka, India</p>
                    </div>
                    <div class="footer-address">
                      <p><i class="feather-phone-call"></i> +91 824 6637145</p>
                    </div>
                    <div class="footer-address mb-0">
                      <p><i class="feather-mail"></i> info@yourdoc.in</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-7">
            <div class="footer-widget">
              <div class="subscribe-form">
                <form action="javascript:;">
                  <h2 style="margin-bottom:10px">Join our Newsletter</h2>
                  <div class="social-icon" style="margin-left: 10px;">
                    <ul>
                      <li>
                        <buttony v-if="!subscribed" @click="toggleSubscription">Subscribe</buttony>
                        <buttonx v-if="subscribed" @click="toggleSubscription">Unsubscribe</buttonx>
                      </li>
                      <li>
                        <a href="https://facebook.com/yourdoc.in" target="https://www.facebook.com/yourdoc.in"><i class="fab fa-facebook"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/yourdocin" target="https://www.instagram.com/yourdocin"><i class="fab fa-instagram"></i></a>
                      </li>
                      <li>
                        <a href="javascript:;" target="_blank"><i class="fab fa-x-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/thegiglabs" target="_blank"><i
                            class="fab fa-linkedin-in"></i></a>
                      </li>

                    </ul>
                  </div>

                  <!-- <button> Subscribe </button> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <div class="container">
        <!-- Copyright -->
        <div class="copyright">
          <div class="row">
            <div class="col-md-6 col-lg-6">
              <div class="copyright-text">
                <p class="mb-0">
                  Copyright © {{ new Date().getFullYear() }}
                  <a href="https://thegiglabs.com" target="_blank">Gigabyte Labs Pvt Ltd</a>
                  All Rights Reserved
                </p>
              </div>
            </div>
            <div class="col-md-6 col-lg-6">
              <!-- Copyright Menu -->
              <div class="copyright-menu">
                <ul class="policy-menu">
                  <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                  <li>
                    <router-link to="/terms-condition">Terms and Conditions</router-link>
                  </li>
                </ul>
              </div>
              <!-- /Copyright Menu -->
            </div>
          </div>
        </div>
        <!-- /Copyright -->
      </div>
    </div>
  </footer>
  <!-- /Footer -->
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
     
      doctorid: '',
      allnewsletters: [],
      doctorids: [],
      newsletter: {
        status: "S",
      },
      updatedNewsletter: {
        status: ''
      },
      subscribed: null,
      statusx:'',
    };
  },
  beforeMount() {
    this.fetchDoctorDetails();
  },
  async mounted() {
    await this.fetchDoctorDetails();
    await this.fetchNewsletters();
  },
  methods: {
    toggleSubscription() {
      const status = this.statusx;
      //console.log('Current status:', status);
      if (this.allnewsletters.length > 0) {
        if (status === 'U') {
          this.updateNewsletter('S'); 
        } else if (status === 'S') {
          this.updateNewsletter('U'); 
        } else {
          this.insertNewsletter();
        }
      } else {
        this.insertNewsletter();
      }
    },
  async fetchDoctorDetails() {
    this.doctorid = sessionStorage.getItem("userdbid");
    //console.log("this is doctor id of current doctor:", this.doctorid);
    try {
      const response = await axios.get(`doctors/${this.doctorid}`);
      this.doctorDetails = response.data;
    } catch (error) {
      console.error("Error fetching doctor details:", error);
    }
  },
  async fetchNewsletters() {
    try {
      const response = await axios.get(`/doctors/${this.doctorid}/newsletter`);
      this.allnewsletters = response.data;
      this.statusx = this.allnewsletters[0].status;
      this.subscribed = (this.statusx === 'S') ? true : false;

    } catch (error) {
      console.error("Error fetching newsletters:", error);
      this.subscribed = false;
    }
  },
  async insertNewsletter() {
    try {
      const response = await axios.post(`/doctors/${this.doctorid}/newsletter`, this.newsletter);
      console.log("Newsletter inserted successfully:"); //, response.data);
      this.subscribed = true;
      this.fetchNewsletters();
    } catch (error) {
      console.error("Error inserting newsletter:", error);
    }
  },
  async updateNewsletter(status) {
    try {
      const updatedNewsletter = {
        status: status  
      };
      const response = await axios.put(`/doctors/${this.doctorid}/newsletter`, updatedNewsletter);
      this.subscribed = (status === 'S');
      this.fetchNewsletters();
    } catch (error) {
      console.error('Error updating doctor newsletter:', error);
    }
  },
},
};
</script>
<style scoped>
buttony {
  /* background-color: #007bff; (facebook-blue) */
  background-color: green;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}

buttonx {
  background-color: grey;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}
</style>
