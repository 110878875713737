<template>
  <div class="chat-cont-left">
    <div  v-if="!showChatDetails || !isMobileView" class="back-link">
      <router-link to @click="$router.go(-1)" class="back-button">
        <i class="fa-solid fa-arrow-left-long back-icon"></i> Back
      </router-link>
    </div>
    <b-alert v-if="errorvalidation" v-model="showDismissibleAlert" variant="danger" dismissible>
      {{ errorvalidation }}
    </b-alert>
    <b-alert v-if="successMessage" v-model="showDismissibleAlert" variant="success" dismissible>
      {{ successMessage }}
    </b-alert>
    <div v-if="!showChatDetails || !isMobileView" class="chat-header d-flex justify-content-between align-items-center">
      <div>
    <label class="custom_check">
      <input
        type="checkbox"
        v-model="selectAll"
        @change="toggleSelectAll"
      />
      <span class="checkmark"></span>
      Select All
    </label>
  </div>
      <span> Notifications </span>
      <button @click="deleteSelectedNotifications" class="btn btn-danger">Delete</button>
    </div>
    <!-- <a href="javascript:void(0)" class="chat-compose">
        <i class="material-icons">control_point</i>
      </a> -->
    
    <!-- <form class="chat-search">
      <div class="input-group">
        <div class="input-group-prepend">
          <i class="fas fa-search"></i>
        </div>
        <input type="text" class="form-control rounded-pill" placeholder="Search" />
      </div>
    </form> -->
    
    <div class="chat-users-list" v-if="!showChatDetails || !isMobileView">
      <div class="chat-scroll">
        <a
          v-for="chat in chats"
          :key="chat.slno"
          href="javascript:void(0);"
          :class="['notify-block d-flex', { 'read-chat': chat.read,'active': chat.slno === selectedChat, 'unread': chat.notificationflag === 'U', 'selected-unread': chat.notificationflag === 'R' || chat.slno === selectedChat }]"
          @click="selectChat(chat)"
        >
          <div class="media-img-wrap flex-shrink-0">
            <label class="custom_check">
				<input
				type="checkbox"
				v-model="selectedChats"
				:value="chat.slno"
				@click.stop
				/>
				<span class="checkmark"></span>
			</label>

            <div :class="['avatar', chat.status]">
              <img src="@/assets/img/doctors/doctor-thumb-01.jpg" alt="User Image" class="avatar-img rounded-circle">
            </div>
          </div>
          <div class="media-body flex-grow-1">
            <div>
              <div class="user-name">{{ chat.title }}</div>
              <div class="user-last-chat">{{ chat.lastMessage }}</div>
            </div>
            <div>
              <div class="last-chat-time block">{{ chat.time }}</div>
              <div v-if="chat.badge" class="badge badge-success rounded-pill">{{ chat.badge }}</div>
            </div>
          </div>
        </a>
      </div>
    </div>
    
    <div v-if="showChatDetails && isMobileView" class="chat-detail-container">
     <button @click="closeChatDetails" class="btn btn-secondary btn-sm float-left">Back</button>
      <div class="chat-detail">
        <div class="chat-body" v-html="selectedChatDescription"></div>
      </div>
    </div>
    <!-- <div class="chat-users-list">
			<div class="chat-scroll">
				<a href="javascript:void(0);" class="notify-block d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-away">
							<img src="@/assets/img/doctors/doctor-thumb-01.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. Ruby Perrin</div>
							<div class="user-last-chat">Hey, How are you?</div>
						</div>
						<div>
							<div class="last-chat-time block">2 min</div>
							<div class="badge badge-success rounded-pill">15</div>
						</div>
					</div>
				</a>
				<a href="javascript:void(0);" class="notify-block read-chat active d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-online">
							<img src="@/assets/img/doctors/doctor-thumb-02.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. Darren Elder</div>
							<div class="user-last-chat">I'll call you later </div>
						</div>
						<div>
							<div class="last-chat-time block">8:01 PM</div>
						</div>
					</div>
				</a>
				<a href="javascript:void(0);" class="notify-block d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-away">
							<img src="@/assets/img/doctors/doctor-thumb-03.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. Deborah Angel</div>
							<div class="user-last-chat">Give me a full explanation about our project</div>
						</div>
						<div>
							<div class="last-chat-time block">7:30 PM</div>
							<div class="badge badge-success rounded-pill">3</div>
						</div>
					</div>
				</a>
				<a href="javascript:void(0);" class="notify-block read-chat d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-online">
							<img src="@/assets/img/doctors/doctor-thumb-04.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. Sofia Brient</div>
							<div class="user-last-chat">That's very good UI design</div>
						</div>
						<div>
							<div class="last-chat-time block">6:59 PM</div>
						</div>
					</div>
				</a>
				<a href="javascript:void(0);" class="notify-block read-chat d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-offline">
							<img src="@/assets/img/doctors/doctor-thumb-05.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. Marvin Campbell</div>
							<div class="user-last-chat">Yesterday i completed the task</div>
						</div>
						<div>
							<div class="last-chat-time block">11:21 AM</div>
						</div>
					</div>
				</a>
				<a href="javascript:void(0);" class="notify-block read-chat d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-online">
							<img src="@/assets/img/doctors/doctor-thumb-06.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. Katharine Berthold</div>
							<div class="user-last-chat">What is the major functionality?</div>
						</div>
						<div>
							<div class="last-chat-time block">10:05 AM</div>
						</div>
					</div>
				</a>
				<a href="javascript:void(0);" class="notify-block read-chat d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-away">
							<img src="@/assets/img/doctors/doctor-thumb-07.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. Linda Tobin</div>
							<div class="user-last-chat">This has allowed me to showcase not only my technical skills</div>
						</div>
						<div>
							<div class="last-chat-time block">Yesterday</div>
						</div>
					</div>
				</a>
				<a href="javascript:void(0);" class="notify-block read-chat d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-offline">
							<img src="@/assets/img/doctors/doctor-thumb-08.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. Paul Richard</div>
							<div class="user-last-chat">Let's talk briefly in the evening. </div>
						</div>
						<div>
							<div class="last-chat-time block">Sunday</div>
						</div>
					</div>
				</a>
				<a href="javascript:void(0);" class="notify-block read-chat d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-online">
							<img src="@/assets/img/doctors/doctor-thumb-09.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. John Gibbs </div>
							<div class="user-last-chat">Do you have any collections? If so, what of?</div>
						</div>
						<div>
							<div class="last-chat-time block">Saturday</div>
						</div>
					</div>
				</a>
				<a href="javascript:void(0);" class="notify-block read-chat d-flex">
					<div class="media-img-wrap flex-shrink-0">
						<div class="avatar avatar-away">
							<img src="@/assets/img/doctors/doctor-thumb-10.jpg" alt="User Image"
								class="avatar-img rounded-circle">
						</div>
					</div>
					<div class="media-body flex-grow-1">
						<div>
							<div class="user-name">Dr. Olga Barlow</div>
							<div class="user-last-chat">Connect the two modules with in 1 day.</div>
						</div>
					<div>
						<div class="last-chat-time block">Friday</div>
					</div>
				</div>
			</a>
		</div> -->
  </div>
</template>

<script>
import axios from 'axios';

export default {
  props: ['chats', 'isMobileView', 'currentDescription'],
  data() {
    return {
      patientid:'',
      selectedChat: '',
      selectedChats: [],
      selectAll: false,
      errorvalidation: '',
      successMessage: '',
      showDismissibleAlert: true,
      showChatDetails: false,
      selectedChatDescription: ''
    };
  },
  watch: {
    currentDescription(newVal) {
      if (!this.isMobileView) {
        this.selectedChatDescription = newVal;
      }
    }
  },
  methods: {
    async selectChat(chat) {
      this.selectedChat = chat.slno;
      this.selectedChatDescription = chat.description;
      this.showChatDetails = true;
      
      if (chat.notificationflag === 'U') {
        try {
          const response = await axios.put(`patients/read-notification/${chat.slno}`, 'R');
          if (response.status === 200) {
            chat.notificationflag = 'R';
            this.$emit('chatSelected', chat.description);
          } else {
            throw new Error('Failed to update notification flag');
          }
        } catch (error) {
          console.error('Error updating notification:', error);
        }
      } else {
        this.$emit('chatSelected', chat.description);
      }
    },
    toggleSelectAll() {
      this.selectedChats = this.selectAll ? this.chats.map(chat => chat.slno) : [];
    },
    async deleteSelectedNotifications() {
      this.patientid = sessionStorage.getItem("userdbid");
      if (this.selectedChats.length === 0) {
        this.errorvalidation = "No notifications selected for deletion";
        this.successMessage = '';
        this.showDismissibleAlert = true;
        return;
      }
      try {
        const response = await axios.delete(`patients/${this.patientid}/notifications`, {
          data: { slno_list: this.selectedChats }
        });
        if (response.status === 200) { 
          this.$emit('refreshChats');
          this.$emit('chatSelected', "");
        } else {
          throw new Error('Failed to delete notifications');
        }
      } catch (error) {
        console.error('Error deleting notifications:', error);
        this.errorvalidation = "Error deleting notifications";
        this.successMessage = '';
        this.showDismissibleAlert = true;
      }
    },
    closeChatDetails() {
      this.showChatDetails = false;
      this.selectedChatDescription = '';
      this.selectedChat = '';
    }
  }
}
</script>

<style scoped>
.back-button {
  background-color: none !important;
  color: black !important;
}

.back-icon {
  margin-right: 5px;
}

.read-chat {
  background-color: #e9ecef;
}

.active {
  background-color: none !important;
  color: white;
}

.unread .user-name {
  font-weight: bold;
}

.selected-unread .user-name {
  font-weight: normal;
}

.chat-detail-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
}

.chat-detail {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}
.chat-users-list .chat-scroll {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
}
@media (max-width: 768px) {
  .chat-detail-container {
    height: 100%;
  }
}
.btn-sm.float-left {
    margin-left: 0;
    margin-right: auto;
  }
</style>
