<template>
  <div id="pat_appointments" class="tab-pane fade show active">
    <div class="card card-table mb-0">
      <div class="align-container">
        <div class="left-align">
          <i class="fa fa-long-arrow-left" aria-hidden="true" @click="scrollTableLeft"></i>
        </div>
        <div class="right-align">
          <i class="fa fa-long-arrow-right" aria-hidden="true" @click="scrollTableRight"></i>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive" ref="tableContainer">
          <table class="table table-hover table-center mb-0">
            <thead>
              <tr>
                <th>Patient Name</th>
                <th>Doctor</th>
                <th>Appt Date</th>
                <th>Booking Date</th>
                <th>Clinic Name</th>
                <!-- <th>Amount</th> -->
                <!-- <th>Follow Up</th> -->
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in futureAppointments" :key="item.appointment_id">
                <td>
                  <h2 class="table-avatar">
                    <span class="avatar avatar-sm me-2">
                      <img src="@/assets/img/patients/patient.jpg" alt="User Image" />
                    </span>
                    {{item.patient_name}}
                  </h2>
                  <!-- <div v-if="item.appointmentstatus !=='C'">
                    <div v-if="!item.tokenNumber" class="check-in-container">
                      <button class="checkin-btn" @click="openModal(item)">Generate Token</button>
                    </div>
                    <div v-else class="check-in-container">
                      <span class="checked-in-label">
                        <i class="fas fa-check"></i>Token Generated
                      </span>
                      <div class="checked-in-box">
                        <div class="token-number">
                          <span>
                            Token No:
                            <b>{{ item.tokenNumber }}</b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </td>
                <td>
                  <h2 class="table-avatar">
                    <router-link :to="'doctor/' + item.doctor_id" class="avatar avatar-sm me-2">
                      <img
                        class="avatar-img rounded-circle"
                        :src="//item.doctor_image ? require('@/assets/img/doctors/'+item.doctor_image) : 
                        require('@/assets/img/doctors/doctor-thumb-01.jpg')"
                        alt="Doctor Image"
                      />
                    </router-link>
                    <router-link :to="'doctor/' + item.doctor_id">
                     Dr. {{ item.doctor_name }}
                      <span>{{ item.speciality }}</span>
                    </router-link>
                  </h2>
                </td>
                <td>
                  {{ formatDate(item.date_and_time) }}
                  <span
                    class="d-block text-info"
                  >{{ formatTime(item.date_and_time) }}</span>
                </td>
                <td>{{ formatDate(item.createddate) }}</td>
                <td>{{ item.clinic_name }}</td>
                <!-- <td>{{ item.amount }}</td> -->
                <!-- <td>{{ item.FollowUp }}</td> -->
                <td>
                  <span
                    :class="getStatusAndClass(item.appointmentstatus).class"
                  >{{ getStatusAndClass(item.appointmentstatus).status }}</span>
                </td>
                <td class="text-end">
                  <div class="table-action">
                    <!-- <a href="javascript:void(0);" class="btn btn-sm bg-primary-light ms-3">
                      <i class="fas fa-print"></i> Print
                    </a>
                    <a href="javascript:void(0);" class="btn btn-sm bg-info-light ms-1">
                      <i class="far fa-eye"></i> View
                    </a>-->
                    <a
                      v-if="item.appointmentstatus === 'E'"
                      href="javascript:void(0);"
                      class="btn btn-sm bg-danger-light me-2"
                      @click="cancelAppointment(item)"
                    >
                      <i class="fas fa-times"></i> Cancel
                    </a>
                    <a
                      v-if="item.appointmentstatus !== 'C'"
                      href="javascript:void(0)"
                      class="btn btn-sm bg-info-light"
                      data-bs-toggle="modal"
                      data-bs-target="#appointment_medical_records_modal"
                      @click="addMedicalRecords(item.appointment_id, item.patientid, item.doctor_id, item.doctor_name)"
                    >
                      <i class="fas fa-file-medical"></i> Add Medical Record
                    </a>
                    <a
                      href="javascript:void(0);"
                      v-if="item.appointmentstatus !== 'C'"
                      class="btn btn-sm bg-info-light ms-1"
                      data-bs-toggle="modal"
                      data-bs-target="#view_medical_records_modal"
                      @click="viewMedicalRecords(item.patientid,item.appointment_id,item.doctor_id)"
                    >
                      <i class="far fa-eye"></i> View Records
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- Check-in Modal -->

  <div v-if="isModalOpen" class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header">
        <h3>Appointment Details</h3>
        <button type="button" class="btn-close" @click="back" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <p>
          Patient Name:
          <strong>{{ selectedAppointment.patient_name }}</strong>
        </p>
        <p>
          Doctor:
          <strong>Dr. {{ selectedAppointment.doctor_name }}</strong>
        </p>
        <p>
          Appointment Date:
          <strong>{{ formatDate(selectedAppointment.date_and_time) }}</strong>
        </p>
        <p>
          Appointment Time:
          <strong>{{ formatTime(selectedAppointment.date_and_time) }}</strong>
        </p>
        <p>
          Clinic:
          <strong>{{ selectedAppointment.clinic_name }}</strong>
        </p>
      </div>
      <b-alert
        v-model="showAlert"
        variant="warning"
        class="mt-2"
      >Tokens can only be generated 30 minutes prior to the Appointment Time.</b-alert>
      <div class="modal-footer">
        <button
          type="button"
          :disabled="isDisabled"
          class="confirm-btn"
          @click="confirmCheckIn(selectedAppointment)"
        >
          <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
          Confirm
        </button>
        <button type="button" class="back-btn" @click="back">Back</button>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import patientappointment from "@/assets/json/patientdashboard/patientappointment.json";
export default {
  props: {
    futureAppointments: {
      type: Array,
      default: () => []
    },
    fetchAppointments: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      showAlert: true,
      isModalOpen: false,
      isDisabled: true,
      loading: false,
      selectedAppointment: {}
      // patientappointment: patientappointment,
    };
  },
  methods: {
    async isValidTime(date_and_time) {
      try {
        const response = await axios.get("/doctors/datetime");
        const [month, date, day, time24, time12] = response.data;
        //console.log("response:", response);
        //console.log(date_and_time);

        const currentDateTimeString = `${date}T${time24}`;
        const currentDateTime = new Date(currentDateTimeString);
        const appointmentDateTime = new Date(date_and_time);
        const appointmentDateTimeMinus30 = new Date(
          appointmentDateTime.getTime() - 30 * 60000
        );
        if (currentDateTime >= appointmentDateTimeMinus30) {
          this.isDisabled = false;
          this.showAlert = false;
        }
      } catch (error) {
        console.error("Error fetching current date and time:", error);
        return false;
      }
    },
    formatedTime(time) {
      return new Date(`2022-01-01T${time}`).toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });
    },

    formatDate(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { day: "numeric", month: "short", year: "numeric" };
      return date.toLocaleDateString("en-US", options);
    },
    formatTime(dateTimeString) {
      const date = new Date(dateTimeString);
      const options = { hour: "2-digit", minute: "2-digit", hour12: true };
      return date.toLocaleTimeString("en-US", options);
    },
    getStatusAndClass(appointmentStatus) {
      let status = "";
      let cssClass = "";

      if (appointmentStatus === "E") {
        status = "Upcoming";
        cssClass = "badge rounded-pill bg-success-light";
      } else if (appointmentStatus === "C") {
        status = "Cancelled";
        cssClass = "badge rounded-pill bg-danger-light";
      } else if (appointmentStatus === "V") {
        status = "Confirmed";
        cssClass = "badge rounded-pill bg-success-light";
      }
      return {
        status,
        class: cssClass
      };
    },
    async openModal(item) {
      await this.isValidTime(item.date_and_time);
      //console.log("appt:", item);
      this.selectedAppointment = item;
      this.isModalOpen = true;
      this.$emit("openModal");
    },
    async confirmCheckIn(item) {
      this.loading = true;
      try {
       //console.log("iteminconfirm", item.patientid);
        const response = await axios.post(
          `/patients/${item.patientid}/appointments/${item.appointment_id}/tokens`
        );
        console.log("Confirmed Check-in "); //for", this.selectedAppointment);
        this.$emit("fetchAppointments");
        this.isModalOpen = false;
        this.$emit("closeModal");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.isModalOpen = false;
      this.showAlert = true;
      this.isDisabled = true;
      this.$emit("closeModal");
    },
    cancelAppointment(appointment) {
      const appointmentby = sessionStorage.getItem("userdbid");

      const { appointment_id, date_and_time, doctor_name } = appointment;

      const [appointmentDate, appointmentTime] = date_and_time.split("T");

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to cancel the appointment with Dr.${doctor_name}  on ${this.formatDate(
          appointmentDate
        )} at ${this.formatedTime(appointmentTime)}?`,
        input: "text",
        showCancelButton: false,
        inputLabel: "Reason for cancellation",
        inputPlaceholder: "Enter reason here...",
        icon: "warning",
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Confirm Cancel",
        customClass: {
          popup: "swal-popup-custom",
          closeButton: "swal-close-button-custom"
        },
        showCloseButton: true,
        closeButtonHtml: "&times;"
      }).then(result => {
        if (result.isConfirmed) {
          const cancelReason = result.value;

          const cancelApiUrl = `/patients/${appointmentby}/appointments/${appointment_id}`;
          axios
            .put(cancelApiUrl, { cancelreason: cancelReason })
            .then(response => {
              console.log("Appointment cancelled successfully"); //, response.data);
              this.fetchAppointments();
            })

            .catch(error => {
              console.error("Error cancelling appointment", error);
            });
        }
      });
    },
    addMedicalRecords(appointmentId, patientId, doctorId, doctor_name) {
      this.$emit("add-medical-records", {
        appointmentId,
        patientId,
        doctorId,
        doctor_name
      });
    },
    viewMedicalRecords(patientId, appointmentId, doctorId) {
      this.$emit("view-medical-records", {
        patientId,
        appointmentId,
        doctorId
      });
    },
    scrollTableRight() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: 200,
        behavior: "smooth"
      });
    },

    scrollTableLeft() {
      const tableContainer = this.$refs.tableContainer;
      tableContainer.scrollBy({
        left: -200,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style scoped>
.left-align {
  text-align: left;
  font-size: 24px;
  margin-left: 10px;
  cursor: pointer;
}

.align-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right-align {
  text-align: right;
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}
.swal-popup-custom {
  position: relative;
}

.swal-close-button-custom {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #d33;
}
.check-in-container {
  margin-left: 57px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  width: 42%;
  max-width: 900px;
  padding: 15px;
  max-height: 44;
  min-height: 44vh;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 10px rgba(71, 70, 70, 0.2);
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.modal-footer {
  margin-top: 1px;
  display: flex;
  justify-content: flex-end;
}
button {
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}
.checkin-btn {
   background-color: #0e82fd;
  border: 1px solid;
  border-radius: 4px;
  color: #fff;
  padding: 2px 7px;
  margin-right: 5px;
  margin-left: 1px;
  font-size: 15px;
}

.checkin-btn:hover {
  background-color: #ffffff;
  border: 1px solid #20c0f3;
  color: #0e82fd;
}

button:disabled {
  background-color: #cccccc;
  color: rgb(75, 75, 75);
  cursor: not-allowed;
  opacity: 0.6;
}
.confirm-btn {
  background-color: rgb(0, 199, 90);
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}
.back-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  margin-left: 5px;
}
.checked-in-box {
  background-color: #e6f5e9;
  border: 1px solid #28a745;
  border-radius: 8px;
  padding: 3px 2px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.checked-in-label {
  font-size: 14px;
  background-color: rgb(0, 199, 90);
  display: flex;
  border-radius: 8px;
  color: white;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.checked-in-label i {
  margin-right: 5px;
}

.token-number {
  margin-top: 1px;
  font-size: 13px;
  color: #333;
  padding: 1px 1px;
}

.token-number b {
  color: #000;
}
</style>