<template>
  <div class="main-wrapper">
    <layoutheader v-if="!showModal"></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>
          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <div>
                  <div v-if="showbookingExistAlert" class="alert alert-danger" v-html="error"></div>

                  <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>{{ error }}</b-alert>
                  <b-alert
                    v-model="showDismissibleSuccess"
                    variant="success"
                    dismissible
                  >{{ success }}</b-alert>
                </div>
                <h4 class="card-title d-flex justify-content-between">
                  Manage Time-Off
                  <button
                    class="main-btn"
                    @click="this.showModal = true"
                  >Add Time-Off</button>
                </h4>
                <div class="table-responsive">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th>From</th>
                        <th>To</th>
                        <th>Reason</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="timeOff in timeOffList" :key="timeOff.slno">
                        <td>{{ formatDate(timeOff.startdate) }}</td>
                        <td>{{ formatDate(timeOff.enddate) }}</td>
                        <td>{{ timeOff.reason }}</td>
                        <td>
                        <span v-if="timeOff.status ==='A' && (new Date(currentDate) > new Date(timeOff.enddate))" class="text-muted">Completed</span>
                          <button
                            v-else-if="timeOff.status === 'A'"
                            class="btn btn-danger btn-sm"
                            @click="deleteTimeOff(timeOff.slno)"
                          >Cancel Time-Off</button>
                          <span v-else class="text-muted">Cancelled</span>
                        </td>
                      </tr>
                      <tr v-if="timeOffList.length === 0">
                        <td colspan="4" class="text-center">No records found.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Add Time-Off Modal -->
    <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h3>Add Time-Off</h3>
          <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
        </div>

        <!-- Modal Body -->
        <div class="modal-body">
          <form @submit.prevent="applyTimeOff">
            <div class="mb-3">
              <label for="clinic" class="form-label">Select Clinic:</label>
              <select id="clinic" v-model="selectedClinic" class="form-select" required>
                <option value disabled>Select a Clinic</option>
                <option
                  v-for="clinic in clinics"
                  :key="clinic.clinicid"
                  :value="clinic.clinicid"
                >{{ clinic.clinicname }}</option>
              </select>
            </div>

            <div class="mb-3">
              <label for="startDate" class="form-label">From:</label>
              <input id="startDate" type="date" class="form-control" v-model="startDate" :min="currentDate"  required />
            </div>

            <div class="mb-3">
              <label for="endDate" class="form-label">To:</label>
              <input id="endDate" type="date" class="form-control" v-model="endDate" :min="startDate || currentDate"  required />
            </div>

            <div class="mb-3">
              <label for="reason" class="form-label">Reason:</label>
              <textarea
                id="reason"
                class="form-control"
                v-model="reason"
                rows="3"
                maxlength="100"
                placeholder="Provide a reason for the time-off..."
                required
              ></textarea>
            </div>
          </form>
        </div>

        <!-- Modal Footer -->
        <div class="modal-footer">
          <button
            type="button"
            class="confirm-btn"
            :disabled="!selectedClinic || !startDate || !endDate || !reason || loading"
            @click="applyTimeOff"
          >
            <span class="spinner-border spinner-border-sm me-2" role="status" v-if="loading"></span>
            Add Time Off
          </button>
          <button type="button" class="back-btn" @click="closeModal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script> 
import axios from "axios"; 
import Swal from 'sweetalert2';
export default {
  data() {
    return {
      title: "Time Off Schedule",
      text: "Home",
      text1: "Time-Off",
      timeOffList: [],
      clinics: [],
      selectedClinic: "",
      startDate: "",
      endDate: "",
      currentDate: "",
      reason: "",
      error: "",
      success: "",
      showDismissibleAlert: false,
      showDismissibleSuccess: false,
      showbookingExistAlert: false,
      showModal: false
    };
  },
  methods: {
    async fetchCurrDate(){
        try {
        const response = await axios.get("/doctors/datetime");
        this.currentDate = response.data[1];
      } catch (error) {
        console.error("Error fetching today's date:", error);
        this.currentDate = new Date().toISOString().split('T')[0];
      }
    },
    formatDate(dateString) {
      if (!dateString) return "";
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Intl.DateTimeFormat("en-US", options).format(
        new Date(dateString)
      );
    },
    async fetchTimeOffs() {
      try {
        const doctorId = sessionStorage.getItem("userdbid");
        const response = await axios.get(`/doctors/${doctorId}/time-off`);
        this.timeOffList = response.data;
      } catch (err) {
        console.error(err);

      }
    },

    async fetchClinics() {
      try {
        const doctorId = sessionStorage.getItem("userdbid");
        const response = await axios.get(`/doctors/${doctorId}/scheduletiming`);
        if (response.data) {
          const clinicMap = new Map();
          response.data.forEach(slot => {
            if (!clinicMap.has(slot.clinicid)) {
              clinicMap.set(slot.clinicid, slot.clinicname);
            }
          });

          this.clinics = Array.from(clinicMap, ([clinicid, clinicname]) => ({
            clinicid,
            clinicname
          }));
        }
      } catch (err) {
        this.error = "Failed to fetch clinics. Please try again later.";
       this.showDismissibleAlert = true;
         setTimeout(() => {
        this.showDismissibleAlert = false
      }, 5000);
 
      }
    },

    async applyTimeOff() {
      if (
        !this.selectedClinic ||
        !this.startDate ||
        !this.endDate ||
        !this.reason
      ) {
        this.error = "All fields are required.";
        this.showDismissibleAlert = true;
         setTimeout(() => {
        this.showDismissibleAlert = false
      }, 5000);

        return;
      }
      const result = await Swal.fire({
    title: 'Confirm Time-Off?',
    text: "Are you sure you want to apply for this time-off? This will block your schedule for appointments during the selected period.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Apply Time-Off',
    cancelButtonText: 'No, Go Back'
  });

  if (result.isConfirmed) {
  try {
    const doctorId = sessionStorage.getItem("userdbid");
    const response = await axios.post(
      `/doctors/${doctorId}/time-off/${this.selectedClinic}?start_date=${encodeURIComponent(
        this.startDate
      )}&end_date=${encodeURIComponent(
        this.endDate
      )}&reason=${encodeURIComponent(this.reason)}`
    );

    if (response.status === 200 && response.data.detail) {
      this.success = response.data.detail;
      this.showDismissibleSuccess = true;
         setTimeout(() => {
        this.showDismissibleSuccess = false
      }, 5000);


      this.fetchTimeOffs();
      this.showModal = false;
      this.selectedClinic = "";
      this.startDate = "";
      this.endDate = "";
      this.reason = "";
    }
  } catch (err) {
    if (err.response?.status === 400 && err.response?.data?.detail) {
      const detailMessage = err.response.data.detail;
      if (
        detailMessage.includes(
          "Booking already exist between the specified dates"
        )
      ) {
      
this.showModal = false;
      this.selectedClinic = "";
      this.startDate = "";
      this.endDate = "";
      this.reason = "";
        this.error = `${detailMessage} <a href="/available-timings" style="color: #007bff; text-decoration: underline;">Click here to cancel slots.</a>`;
      this.showbookingExistAlert = true;
      
return;
      } else {
        this.error = detailMessage || "An error occurred while submitting the request.";
      }
    } else {
      this.error =
        err.response?.data?.message ||
        "Failed to submit the time-off request. Please try again later.";
    }
this.showModal = false;
      this.selectedClinic = "";
      this.startDate = "";
      this.endDate = "";
      this.reason = "";
    this.showDismissibleAlert = true;
     setTimeout(() => {
        this.showDismissibleAlert = false
      }, 5000);

  }
  }
    },

closeModal(){
    this.showModal = false;
      this.selectedClinic = "";
      this.startDate = "";
      this.endDate = "";
      this.reason = "";
},
    async deleteTimeOff(slno) {
        const result = await Swal.fire({
    title: 'Are you sure you want to cancel this time-off?',
    text: "Cancelling this time-off will reopen your schedule for patients to book appointments during this period.",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, Cancel Time-Off',
    cancelButtonText: 'No, Keep Time-Off'
  });
    if(result.isConfirmed){
      try {
        const doctorId = sessionStorage.getItem("userdbid");
        await axios.put(`/doctors/time-off/${slno}`);
        this.success = "Time-off Cancel successfully.";
        this.showDismissibleSuccess = true;
        setTimeout(() => {
        this.showDismissibleSuccess = false
      }, 5000);
        this.fetchTimeOffs();
      } catch (err) {
        this.error = "Failed to Cancel time-off. Please try again.";
        this.showDismissibleAlert = true;
         setTimeout(() => {
        this.showDismissibleAlert = false
      }, 5000);

      }
    }
    }
  },
  beforeMount() {
    this.fetchCurrDate();
    this.fetchClinics();
    this.fetchTimeOffs();
  }
};
</script>

<style scoped>

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.modal-body {
  margin-bottom: 20px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
}

.confirm-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
}

.confirm-btn:disabled {
  background-color: #ccc;
}

.main-btn {
  background-color: #0e82fd;
  border: 1px solid;
  border-radius: 8px;
  color: #fff;
  padding: 8px 16px;
  font-size: 17px;
}
.main-btn:hover {
  background-color: #ffffff;
  border: 1px solid #0e82fd;
  color: #0e82fd;
}

.back-btn {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
}
</style>
