<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
    <!-- Page Content -->
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>
          <div class="col-md-7 col-lg-8 col-xl-9">
            <form @submit.prevent="updateDoctorDetails">
              <!-- Basic Information -->
              <div class="card">
                <div class="card-body">
                  <p v-if="errorvalidation">
                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
                      {{ errorvalidation
                      }}
                    </b-alert>
                  </p>
                  <div>
                    <b-alert v-model="showError" dismissible variant="danger">{{ error }}</b-alert>
                  </div>
                  <div>
                    <b-alert v-model="showSuccessAlert" variant="success" dismissible>{{ successMessage }}</b-alert>
                  </div>
                  <!-- <h4 class="card-title">Basic Information</h4> -->

                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex align-items-center mb-3" style="margin-left: 10px; margin-top: 10px;">
                        <div class="change-avatar">
                          <div class="profile-img">
                            <img :src="profileImageUrl || defaultImageUrl" alt="User Image" />
                          </div>
                          <div class="upload-img">
                            <div class="change-photo-btn">
                              <span>
                                <i class="fa fa-upload"></i> Upload Photo
                              </span>
                              <input type="file" class="upload" @change="uploadImage" />
                            </div>
                            <small class="form-text text-muted">Allowed file types: JPG, JPEG, or PNG. Maximum file size: 1MB.</small><br>
                            <small class="form-text text-muted">Recommended dimensions: 225x225 pixels.</small>
                          </div>
                        </div>
                        <div class="ml-auto" style="margin-right: 10px; margin-top: 10px">
                          <qrcode-vue :value="shareableUrl" :size="100"></qrcode-vue>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">
                          Name
                          <!--span class="text-danger">*</span-->
                        </label>
                        <div class="input-group">
                          <input type="text" class="form-control" v-model="doctorDetails.doctorname" maxlength="100"
                            @input="validateName" :class="{ 'is-invalid': nameError }">
                        </div>
                        </input>
                        <div class="invalid-feedback" v-if="nameError">{{ nameError }}</div>
                      </div>

                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">
                          Email ID
                          <!--span class="text-danger">*</span-->
                        </label>
                        <input type="text" class="form-control" readonly v-model="doctorDetails.email" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">
                          Age
                          <!--span class="text-danger">*</span-->
                        </label>
                        <input type="text" class="form-control" v-model="doctorDetails.age" maxlength="2"
                          @input="validateAge" :class="{ 'is-invalid': ageError }" />
                        <div class="invalid-feedback" v-if="ageError">{{ ageError }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Languages Spoken</label>
                        <VueMultiselect v-model="doctorDetails.languagesspoken" :options="languageOptions" :max="5"
                          placeholder="Select languages" label="label" track-by="value" multiple>
                          <template slot="tag" slot-scope="{ option }">
                            <span class="custom__tag">{{ option.label }}</span>
                          </template>
                        </VueMultiselect>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="mb-0">
                        <label class="mb-2">Web URL</label>
                        <div class="input-wrapper" style="position: relative; width: 100%;">
                        <input type="text" class="form-control" v-model="doctorDetails.weburl"
                          @click="goToUrl(doctorDetails.weburl)" style="cursor: pointer; user-select: none;"
                          maxlength="250" readonly>
                          <button type="button" style="position: absolute; top: 50%; right: 10px; transform: translateY(-50%); background: none; border: none; cursor: pointer;">
                            <i class="fas fa-eye" style="font-size: 1.2em; color: grey;"></i>
                        </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- /Basic Information -->
              <!-- Registration -->
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Registrations</h4>
                  <div class="registrations-info">
                    <div class="row reg-cont">
                      <div class="col-md-6">
                        <div class="mb-0">
                          <label class="mb-2">Registration Info</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="doctorDetails.registration_info"
                            maxlength="50"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Registration -->

              <!-- Clinic Info -->
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Clinic Info</h4>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Clinic Name</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="doctorDetails.clincorhospname"
                          maxlength="100"
                        />
                      </div>
                    </div>
                    <!--div class="col-md-6">
                    <div class="mb-3">
                      <label class="mb-2">Clinic Address</label>
                      <input type="text" class="form-control" />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="mb-2">Clinic Images</label>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <div
                            class="call-option file-option"
                            @dragover="onDragOver"
                            @dragleave="onDragLeave"
                            @drop="onFileDrop"
                            :class="{ 'file-over': isFileOver }"
                          >
                            <input
                              type="file"
                              id="radio1"
                              name="selector"
                              class="option-radio"
                              style="display: none"
                              ref="fileInput"
                              @change="onFileChange"
                            />
                            <label
                              for="radio1"
                              class="call-lable verify-lable verify-file"
                            >
                              <div v-if="!file">Drop files here to upload</div>
                              <div v-else>
                                <img :src="filePreview" alt="Uploaded Image" />
                                
                                <a
                                  href="javascript:void(0);"
                                  class="remove-btn"
                                  @click="removeImage"
                                >
                                  <i class="far fa-trash-alt"></i>
                                </a>
                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="upload-wrap">
                      <div class="upload-images">
                        <img
                          src="@/assets/img/features/feature-01.jpg"
                          alt="Upload Image"
                        />
                        <a
                          href="javascript:void(0);"
                          class="btn btn-icon btn-danger btn-sm"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </div>
                      <div class="upload-images">
                        <img
                          src="@/assets/img/features/feature-02.jpg"
                          alt="Upload Image"
                        />
                        <a
                          href="javascript:void(0);"
                          class="btn btn-icon btn-danger btn-sm"
                          ><i class="far fa-trash-alt"></i
                        ></a>
                      </div>
                    </div>
                    </div-->
                  </div>
                </div>
              </div>
              <!-- /Clinic Info -->
              <!--        <doctorprofile-settings1></doctorprofile-settings1>-->
              <!-- Contact Details -->
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Contact Details</h4>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Address</label>
                        <textarea class="form-control" v-model="doctorDetails.address" maxlength="500"></textarea>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Area</label>
                        <select v-model="doctorDetails.area" class="form-control">
                          <option value="" disabled>Select an area</option>
                          <option v-for="areaOption in areaOptions" :key="areaOption.value" :value="areaOption.value">
                            {{ areaOption.label }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Area Code</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="doctorDetails.areacode"
                          maxlength="10"
                          @input="validateAreacode"
                          :class="{ 'is-invalid': areacodeError }"
                        />
                        <div class="invalid-feedback" v-if="areacodeError">{{ areacodeError }}</div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label class="mb-2">Contact Numbers</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="doctorDetails.contactnumbers"
                          maxlength="50"
                          @input="validateContactNumber"
                          :class="{ 'is-invalid': contactError }"
                        />
                        <div class="invalid-feedback" v-if="contactError">{{ contactError }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Contact Details -->

              <!-- Services and Specialization -->
              <div class="card services-card">
                <div class="card-body">
                  <h4 class="card-title">Services and Specialization</h4>
                  <!--div class="mb-3">
        <label class="mb-2">Services</label>
        <vue3-tags-input
          type="text"
          data-role="tagsinput"
          id="services"
          class="input-tags form-control"
          name="services"
          :tags="tags"
          placeholder="Enter Services"
        />
        <small class="form-text text-muted"
          >Note : Type & Press enter to add new services</small
        >
                  </div-->
                  <div class="mb-0">
                    <label class="mb-2">Specialization</label>
                    <select class="form-control" v-model="doctorDetails.specialization">
                      <option
                        v-for="speciality in specialities"
                        :key="speciality.id"
                        :value="speciality.Title"
                      >{{ speciality.Title }}</option>
                    </select>

                    <!--vue3-tags-input
          type="text"
          data-role="tagsinput"
          id="specialist"
          class="input-tags form-control"
          name="specialist"
          :tags="tags1"
          placeholder="Enter Specialization"
                    /-->
                  </div>
                </div>
              </div>
              <!-- /Services and Specialization -->
              <!--doctorprofile-settings2></doctorprofile-settings2-->

              <!-- Education -->
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Education & Experience</h4>
                  <div class="education-info">
                    <div class="row education-cont">
                      <div class="col-12 col-md-10 col-lg-11">
                        <div class="row">
                          <div class="col-12">
                            <div class="mb-3">
                              <label class="mb-2">Qualifications</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="doctorDetails.qualifications"
                                maxlength="1000"
                              />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="mb-3">
                              <label class="mb-2">Experience</label>
                              <input
                                type="text"
                                class="form-control"
                                v-model="doctorDetails.experience"
                                maxlength="2"
                                @input="validateExperience"
                                :class="{ 'is-invalid': experienceError }"
                              />
                              <div
                                class="invalid-feedback"
                                v-if="experienceError"
                              >{{ experienceError }}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Education -->
              <!-- About Me -->
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">About Me</h4>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-0">
                        <label class="mb-2">Biography</label>
                        <textarea
                          class="form-control"
                          v-model="doctorDetails.description"
                          rows="10"
                          maxlength="1000"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /About Me -->

              <div class="submit-section submit-btn-bottom">
                <b-button type="submit" class="btn btn-primary prime-btn">Save Changes</b-button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <doctorfooter></doctorfooter>
  </div>
  <!-- /Main Wrapper -->
</template>
<script>
import axios from "axios";
import IndexBanner from "@/assets/json/specialization_settings_doctor.json";
import VueMultiselect from "vue-multiselect";
import defaultImageUrl from "@/assets/img/doctors/doctor-thumb-01.jpg";
import areaOptions from "@/assets/json/doctor/doctorarea.json";
import eventBus from "@/eventBus";
import QrcodeVue from 'qrcode.vue';

export default {
  components: { VueMultiselect, QrcodeVue },
  data() {
    return {
      title: "Profile Settings",
      text: "Home",
      text1: "Profile Settings",
      sorting: ["Select", "Male", "Female"],
      file: null,
      filePreview: null,
      isFileOver: false,
      doctorDetails: {
        doctorname: "",
        age: "",
        languagesspoken: [],
        specialization: "",
        qualifications: "",
        experience: "",
        contactnumbers: "",
        email: "",
        area: null,
        areacode: "",
        address: "",
        registration_info: "",
        clincorhospname: "",
        description: "",
        weburl: ""
      },
      areaOptions: areaOptions,
      languageOptions: [
        { label: "English", value: "English" },
        { label: "Hindi", value: "Hindi" },
        { label: "Kannada", value: "Kannada" },
        { label: "Tulu", value: "Tulu" },
        { label: "Malayalam", value: "Malayalam" },
        { label: "Konkani", value: "Konkani" },
        { label: "Urdu", value: "Urdu" },
        { label: "Tamil", value: "Tamil" },
        { label: "Telugu", value: "Telugu" },
      ],
      showDismissibleAlert: false,
      errorvalidation: "",
      specialities: IndexBanner,
      nameError: "",
      ageError: "",
      areacodeError: "",
      contactError: "",
      experienceError: "",
      imageFile: null,
      error: null,
      showError: false,
      showSuccessAlert: false,
      successMessage: "",
      profileImageUrl: "",
      defaultImageUrl: defaultImageUrl,
      showShareIcons: false,

    };
  },
  beforeMount() {
    this.fetchDoctorDetails();
  },

  computed: {
    shareableUrl() {
      return `https://api.whatsapp.com/send?text=${encodeURIComponent('Doctor profile of ' + this.doctorDetails.doctorname + ': ' + this.doctorDetails.weburl)}`;
    }
  },

  methods: {
    goToUrl(url) {
      if (url) {
        window.open(url, '_blank');
      }
    },
    setAlertTimeout() {
      setTimeout(() => {
        this.showDismissibleAlert = false;
        this.showSuccessAlert = false;
      }, 5000);
    },
    addTag(newTag) {
      const tag = {
        name: newTag,
        code: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
      };
      this.options.push(tag);
      this.value.push(tag);
    },
    validateName() {
      const regex = /^[a-zA-Z\s]*$/;
      if (!regex.test(this.doctorDetails.doctorname)) {
        this.nameError = "Name should only contain alphabetical characters.";
        return false;
      } else {
        this.nameError = "";
        return true;
      }
    },
    validateAge() {
      const regex = /^\d*$/;
      if (!regex.test(this.doctorDetails.age)) {
        this.ageError = "Age should contain only numbers.";
        return false;
      } else {
        this.ageError = "";
        return true;
      }
    },
    validateAreacode() {
      const regex = /^\d*$/;
      if (!regex.test(this.doctorDetails.areacode)) {
        this.areacodeError = "Areacode should contain only numbers.";
        return false;
      } else {
        this.areacodeError = "";
        return true;
      }
    },
    validateContactNumber() {
      const regex = /^[\d, ]*$/;
      if (!regex.test(this.doctorDetails.contactnumbers)) {
        this.contactError = "ContactNumber should contain only numbers.";
        return false;
      } else {
        this.contactError = "";
        return true;
      }
    },
    validateExperience() {
      const regex = /^\d*$/;
      if (!regex.test(this.doctorDetails.experience)) {
        this.experienceError = "Experience should contain only numbers.";
        return false;
      } else {
        this.experienceError = "";
        return true;
      }
    },
    async fetchDoctorDetails() {
      this.doctorid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(`doctors/${this.doctorid}`);
        this.doctorDetails = response.data;
        if (this.doctorDetails.languagesspoken) {
          const languagesArray = this.doctorDetails.languagesspoken
            .split(",")
            .map(lang => ({ label: lang.trim(), value: lang.trim() }));
          this.doctorDetails.languagesspoken = languagesArray;
        }
        this.fetchPatientProfileImage();
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    async uploadImage(event) {
      const doctorid = sessionStorage.getItem("userdbid");
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
      if (!allowedTypes.includes(file.type)) {
        this.errorvalidation = "Only JPG, JPEG, or PNG images are allowed.";
        this.showDismissibleAlert = true;
        this.setAlertTimeout();
        return;
      }

      const maxSize = 1 * 1024 * 1024;
      if (file.size > maxSize) {
        this.errorvalidation = "File size exceeds 1MB. Please choose a smaller file";
        this.showDismissibleAlert = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.successMessage = "";
        return;
      }

      const formData = new FormData();
      formData.append("image_file", file);
      // formData.append("doctorid", doctorid);

      try {
        const response = await axios.post(
          `doctors/doc-upload-image?doctorid=${doctorid}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          }
        );

        this.fetchDoctorDetails();
        eventBus.emit('dimageUploaded');
        this.showSuccessAlert = true;
        this.setAlertTimeout();
        this.successMessage = "Profile image uploaded successfully!";
      } catch (error) {
        this.errorvalidation = "Failed to upload image. Please try again.";
        this.showDismissibleAlert = true;
        this.setAlertTimeout();
      }
    },
    async fetchPatientProfileImage() {
      const doctorid = sessionStorage.getItem("userdbid");
      try {
        const response = await axios.get(
          `doctors/doctorimg/${doctorid}/download`,
          {
            responseType: "arraybuffer"
          }
        );

        const objectURL = window.URL.createObjectURL(new Blob([response.data]));
        this.profileImageUrl = objectURL;
      } catch (error) {
        console.error("Error fetching patient profile image:", error);
      }
    },
    async updateDoctorDetails() {
      try {
        if (!this.doctorDetails.doctorname) {
          this.errorvalidation = "The doctor’s name cannot be empty";
          this.showDismissibleAlert = true;
          this.setAlertTimeout();
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }
        if (!this.validateName()) {
          this.errorvalidation = "Please enter a valid Name";
          this.showDismissibleAlert = true;
          this.setAlertTimeout();
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }

        if (!this.doctorDetails.age || !this.validateAge()) {
          this.errorvalidation = "Please enter a valid age";
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.showDismissibleAlert = true;
          this.setAlertTimeout();
          return;
        }

        if (
          !this.doctorDetails.languagesspoken ||
          this.doctorDetails.languagesspoken.length > 50
        ) {
          this.errorvalidation =
            "Please enter the languages spoken; the entry must not exceed 50 characters";
          this.showDismissibleAlert = true;
          this.setAlertTimeout();
          window.scrollTo({ top: 0, behavior: "smooth" });
          return;
        }

        if (!this.doctorDetails.email) {
          this.errorvalidation = "Please enter the email";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.setAlertTimeout();
          return;
        }

        if (
          !this.doctorDetails.registration_info ||
          this.doctorDetails.registration_info.length > 50
        ) {
          this.errorvalidation =
            "Please enter the registration info; the entry must not exceed 50 characters";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.setAlertTimeout();
          return;
        }

        if (
          !this.doctorDetails.clincorhospname ||
          this.doctorDetails.clincorhospname.length > 100
        ) {
          this.errorvalidation =
            "Please enter the clinic name; the entry must not exceed 100 characters";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.setAlertTimeout();
          return;
        }

        if (!this.doctorDetails.area || this.doctorDetails.area.length > 25) {
          this.errorvalidation =
            "Please enter the area; the entry must not exceed 25 characters";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.setAlertTimeout();
          return;
        }

        if (!this.doctorDetails.areacode || !this.validateAreacode()) {
          this.errorvalidation = "Please enter valid area code";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.setAlertTimeout();
          return;
        }

        if (
          !this.doctorDetails.address ||
          this.doctorDetails.address.length > 500
        ) {
          this.errorvalidation =
            "Please enter the address; the entry must not exceed 500 characters";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.setAlertTimeout();
          return;
        }

        if (
          !this.doctorDetails.specialization ||
          this.doctorDetails.specialization.length > 50
        ) {
          this.errorvalidation =
            "Please enter the specialization; the entry must not exceed 50 characters";
          this.showDismissibleAlert = true;
          this.setAlertTimeout();
          return;
        }

        if (
          !this.doctorDetails.qualifications ||
          this.doctorDetails.qualifications.length > 1000
        ) {
          this.errorvalidation =
            "Please enter the qualifications; the entry must not exceed 1000 characters";
          this.showDismissibleAlert = true;
          this.setAlertTimeout();
          return;
        }

        if (!this.doctorDetails.experience || !this.validateExperience()) {
          this.errorvalidation = "Please enter the valid experience";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.setAlertTimeout();
          return;
        }

        if (
          !this.doctorDetails.contactnumbers ||
          !this.validateContactNumber()
        ) {
          this.errorvalidation = "Please enter the valid contact numbers";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.setAlertTimeout();
          return;
        }

        if (!this.doctorDetails.description) {
          this.errorvalidation = "Please enter the description";
          this.showDismissibleAlert = true;
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.setAlertTimeout();
          return;
        }

        this.doctorDetails.languagesspoken = this.doctorDetails.languagesspoken
          .map(lang => lang.value)
          .join(", ");

        const response = await axios.put(
          `doctors/${this.doctorid}`,
          this.doctorDetails
        );
        this.errorvalidation = "";
        this.showSuccessAlert = true;
        this.successMessage = "Doctor details updated successfully!";
        window.scrollTo({ top: 0, behavior: "smooth" });
        sessionStorage.setItem("loginusername", this.doctorDetails.doctorname);
        eventBus.emit("updateGivenName", this.doctorDetails.doctorname);
        this.fetchDoctorDetails();
        this.setAlertTimeout();
      } catch (error) {
        console.error("Error updating doctor details:", error);
        this.showDismissibleAlert = true;
        this.errorvalidation =
          "Failed to update Doctor details. Please check details entered/Try again later.";
        this.setAlertTimeout();
      }
    },
    onDragOver(event) {
      event.preventDefault();
      this.isFileOver = true;
    },
    onDragLeave(event) {
      event.preventDefault();
      this.isFileOver = false;
    },
    onFileChange(event) {
      const file = event.target.files[0];
      this.file = file;
      this.filePreview = URL.createObjectURL(file);
    },
    onFileDrop(event) {
      event.preventDefault();
      this.isFileOver = false;
      const file = event.dataTransfer.files[0];
      this.file = file;
      this.filePreview = URL.createObjectURL(file);
    },
    removeImage() {
      this.file = null;
      this.filePreview = null;
      this.$refs.fileInput.value = "";
    }
  }
};
</script>
<style>
.d-flex {
  display: flex;
}

.form-control:disabled {
  background-color: hsla(0, 0%, 94%, 0.149);
  color: black;
}

.align-items-center {
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

.grey-icon {
  color: grey;
  transition: color 0.3s ease;
}

.grey-icon:hover {
  color: black;
}

.share-icons {
  display: flex;
  align-items: center;
  position: absolute;
  right: 50px;
}

.file-over {
  border: 2px dashed #ccc;
}

.file-option img {
  width: 120px;
  height: 120px;
}

.call-lable {
  position: relative;
}

.remove-btn {
  position: absolute;
  top: 23px;
  right: 45%;
}

.remove-btn i {
  color: #e63333;
}


</style>