<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb-doctor :title="title" :text="text" :text1="text1" />
    <div class="content">
      <div class="container">
        <div class="row">
          <doctorsidebar></doctorsidebar>
          <div class="col-md-7 col-lg-8 col-xl-9">
            <div>
              <!-- start_of_error_message -->
              <div>
                <b-alert v-model="showError" dismissible variant="danger">{{ error }} <strong> <a
                      @click="registerPatient"></a></strong></b-alert>
                <b-alert v-model="showNoResultsAlert" dismissible variant="warning">
                  No results found for your search criteria.
                </b-alert>
              </div>
              <!-- end_of_error_message -->
              <!-- start_of_search_bar -->
              <div class="row align-items-center inner-banner">
                <div class="search-box-one aos">
                  <form @submit.prevent="submitForm">
                    <div class="search-input search-map-line">
                      <i class="feather-phone"></i>
                      <div class="form-group mb-0">
                        <!-- <select v-model="patient.countryCode" class="country-code-select">
                        <option v-for="country in allowedCountries" :value="country.dialCode" :key="country.Code">
                          ({{ country.dialCode }}) {{ country.code }}
                        </option>
                      </select> -->
                        <input v-model="patient.contactnumbers" type="text" class="form-control"
                          placeholder="Search by Contact number"
                          :class="{ 'is-invalid': phoneTouched && !validatePhoneNumber() }" @blur="phoneTouched = true"
                          maxlength="12" />
                      </div>
                    </div>
                    <div class="search-input search-map-line">
                      <i class="feather-mail"></i>
                      <div class="form-group mb-0">
                        <input v-model="patient.email" type="text" class="form-control"
                          placeholder="Search patient by Email"
                          :class="{ 'is-invalid': emailTouched && !validateEmail() }" @blur="emailTouched = true"
                          maxlength="45" />
                      </div>
                    </div>
                    <div class="search-input search-map-line">
                      <i class="feather-user"></i>
                      <div class="form-group mb-0">
                        <input v-model="patient.firstname" type="text" class="form-control"
                          placeholder="Search patient by Name"
                          :class="{ 'is-invalid': firstnameTouched && !validatefirstname() }"
                          @blur="firstnameTouched = true" maxlength="45" />
                      </div>
                    </div>
                    <div class="form-search-btnx col-12 text-center">
                      <b-button class="btn" type="submit">Search</b-button>
                    </div>
                  </form>
                </div>
              </div>
              <!-- end_of_search_bar -->
              <!-- start_of_grid-based_search_results -->
              <!-- <div>
                <div>
                  <div class="col-md-16 col-lg-17 col-xl-11 mx-auto" style="margin-top: 30px;">
                    <div class="tab-pane show active" id="today-appointments">
                      <div class="card card-table mb-0">
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-hover table-center mb-0">
                              <tbody v-if="searchResults.length > 0 && searchPerformed === true">
                                <tr v-for="patient in searchResults" :key="patient.id">
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link :to="'patient-profile/' + patient.patientid"
                                        class="avatar avatar-sm me-2">
                                        <img :src="profileImageUrl" alt="User Image" />
                                      </router-link>
                                      <router-link :to="'patient-profile/' + patient.patientid">
                                        {{ patient.firstname }} {{ patient.lastname }}
                                        <span>{{ patient.email }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link :to="'patient-profile/' + patient.patientid">
                                        Contact Number
                                        <span>{{ patient.contactnumbers }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link :to="'patient-profile/' + patient.patientid">
                                        {{ patient.dateof_birth }}
                                        <span>{{ patient.gender }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                  <td>
                                    <h2 class="table-avatar">
                                      <router-link :to="'patient-profile/' + patient.patientid">
                                        {{ patient.area }}
                                        <span>{{ patient.address }} - {{ patient.areacode }}</span>
                                      </router-link>
                                    </h2>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- end_of_grid-based_search_results -->
              <!-- start_of_box-based_search_results -->
              <div class="container-fluid h-100"> <!-- Full width and full height of the viewport -->
                <div class="row justify-content-center align-items-center h-100">
                  <div v-for="patient in searchResults" :key="patient.id" class="col-md-6 col-lg-4 col-xl-3"
                    style="margin-top: 10px;">
                    <div class="card widget-profile pat-widget-profile">
                      <div class="card-body">
                        <div class="pro-widget-content">
                          <div class="profile-info-widget">
                            <router-link :to="'patient-profile/' + patient.patientid" class="booking-doc-img">
                              <img :src="getPatientProfileImage(patient.patientid, patient.profile_path)"
                                alt="User Image" />
                            </router-link>

                            <div class="profile-det-info">
                              <h5>
                                <router-link :to="'patient-profile/' + patient.patientid">{{ patient.firstname
                                  }}</router-link>
                                  <br>
                                  <router-link :to="'patient-profile/' + patient.patientid">{{ patient.lastname
                                  }}</router-link>
                              </h5>

                              <div class="patient-det-infox">
                                <!--h5>
                                <b>Patient ID :</b>
                                {{ patient.patientid }}
                              </h5-->
                                <h6 class="mb-0">
                                  <i class="fas fa-map-marker-alt me-1"></i>
                                  {{ patient.area }}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="patient-info">
                          <ul>
                            <li>
                              Phone
                              <span><a :href="'tel:' + patient.contactnumbers">{{ patient.contactnumbers }}</a></span>
                            </li>
                            <li class="profile-item">
                              Gender: {{ patient.gender }}
                              <!-- <span>{{ patient.gender }}</span> -->
                            </li>
                            <li class="profile-item align-right">
                              Age: {{ patient.age }}
                              <!-- <span>{{ patient.age }}</span> -->
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end_of_box-based_search_results -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <doctorfooter></doctorfooter>
  </div>
</template>
<script>
import RegisterByDoc from '@/views/frontend/pages/doctors/appointmentbydoctor/registerby-doc.vue';
import defaultImageUrl from "@/assets/img/patients/patient.jpg";
import axios from 'axios';
export default {
  components: {
    'registerby-doc': RegisterByDoc,
  },
  data() {
    return {
      title: "My Patients",
      text: "Home",
      text1: "My Patients",
      doctorDetails: [],
      patient: {
        countryCode:'+91',
        contactnumbers: '',
        email: '',
        firstname: '',
        patientid: ''
      },
      allowedCountries: [
          { name: 'India', code: 'IN', dialCode: '+91' },
          { name: 'United States', code: 'US', dialCode: '+1' },
          { name: 'United Kingdom', code: 'GB', dialCode: '+44' },
          { name: 'Canada', code: 'CA', dialCode: '+1' },
          { name: 'Australia', code: 'AU', dialCode: '+61' },
          { name: 'United Arab Emirates', code: 'AE', dialCode: '+971' },
          { name: 'Bahrain', code: 'BH', dialCode: '+973' },
          { name: 'Kuwait', code: 'KW', dialCode: '+965' },
          { name: 'Oman', code: 'OM', dialCode: '+968' },
          { name: 'Qatar', code: 'QA', dialCode: '+974' },
          { name: 'Saudi Arabia', code: 'SA', dialCode: '+966' },
          { name: 'Singapore', code: 'SG', dialCode: '+65' },
          { name: 'Malaysia', code: 'MY', dialCode: '+60' },
          { name: 'New Zealand', code: 'NZ', dialCode: '+64' },
        ],
      // profile_image
      defaultImageUrl: defaultImageUrl,
      profileImageUrl: "",
      doctorid: null,
      patientid: null,
      searchResults: [],
      // validation_and_errormessage
      showError: false,
      error: null,
      firstnameTouched: false,
      emailTouched: false,
      phoneTouched: false,
      searchPerformed: null,
      showNoResultsAlert: false,
      patientProfileImages: {}
    }
  },
  methods: {
    registerPatient() {
      this.showRegisterForm = true;
      this.searchPerformed = false;
    },
    // searchbar_method
    // start_of_new_searchbar
    async searchPatients() {
  try {
    // const contactNumber= (this.patient.contactnumbers)?`${this.patient.countryCode}${this.patient.contactnumbers}`:"";
    const response = await axios.get(`/doctors/${this.doctorid}/patients`, {
      params: { 
        contactnumbers: this.patient.contactnumbers,
        email: this.patient.email,
        firstname: this.patient.firstname
      }
    });
    
    if (response.data && Array.isArray(response.data)) {
      this.searchResults = response.data;
      
      if (this.searchResults.length === 0) {
        this.showNoResultsAlert = true;
      } else {
        this.showNoResultsAlert = false;
        
        // Optionally, fetch profile images for all patients in search results
        this.searchResults.forEach(patient => {
          this.fetchPatientProfileImage(patient.patientid, patient.profile_path);
        });
      }
    } else {
      throw new Error('Unexpected API response structure');
    }
  } catch (error) {
    this.error = error.message || 'An error occurred during the search.';
    this.showError = true;
  }
},

    // end_of_new_searchbar
    async fetchDoctorDetails() {
      const doctorId = sessionStorage.getItem("userdbid");
      this.doctorid = doctorId;
      try {
        let docDetails = sessionStorage.getItem(`doctorDetails${doctorId}`);
        if (!docDetails) {
          const response = await axios.get(`/doctors/${doctorId}`);
          docDetails = response.data;
          sessionStorage.setItem(
            `doctorDetails${this.doctorId}`,
            JSON.stringify(docDetails)
          );
        } else {
          docDetails = JSON.parse(docDetails);
        }
        this.doctorDetails = docDetails;
        //console.log("Doctor Details:", this.doctorDetails);
      } catch (error) {
        console.error("Error fetching doctor details:", error);
      }
    },
    // start_of_validation_method
    validatefirstname() {
      const firstnameRegex = /^([A-Za-z\s]*)$/
      return firstnameRegex.test(this.patient.firstname);
    },
    validateEmail() {
      const emailRegex = /^$|^([a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.[a-zA-Z][a-zA-Z0-9-]{0,45}[a-zA-Z])$/;
      return emailRegex.test(this.patient.email);
    },
    validatePhoneNumber() {
      const phoneNumberRegex = /^\d*$/;
      return phoneNumberRegex.test(this.patient.contactnumbers);
    },
    // end_of_validation_method
    // start_of_submit/search_method
    submitForm() {
      this.showError = false;
      let invalids = [];
      if (!this.patient.contactnumbers && !this.patient.email && !this.patient.firstname) {
        this.error = "Please enter contact number or email, or name";
        this.showError = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      if (this.patient.firstname && !this.validatefirstname()) {
        invalids.push("valid first name");
      }
      if (this.patient.email && !this.validateEmail()) {
        invalids.push(" valid email");
      }
      if (this.patient.contactnumbers && !this.validatePhoneNumber()) {
        invalids.push("valid Phone number");
      }
      if (invalids.length > 0) {
        this.error = "Please enter " + invalids.join(", ");
        this.showError = true;
        window.scrollTo({ top: 0, behavior: "smooth" });
        return;
      }
      this.searchPatients();
      this.searchPerformed = true;
    },
    // end_of_submit/search_method
    // start_of_patient_profile_method
    async fetchPatientProfileImage(patientId, profile_path) {
      try {
        //console.log("this is profile path", profile_path);
        if (profile_path == null) {
          this.patientProfileImages[patientId] = this.defaultImageUrl;

        }
        else {
          const response = await axios.get(
            `/patients/${patientId}/profile-image`,
            {
              responseType: "arraybuffer"
            }
          );
          const objectURL = window.URL.createObjectURL(new Blob([response.data]));
          this.patientProfileImages[patientId] = objectURL;
        }
      } catch (error) {
        console.error("Error fetching patient profile image:", error);
        this.patientProfileImages[patientId] = this.defaultImageUrl;
      }
    },
    // end_of_patient_profile_method
  },
  computed: {
    getPatientProfileImage() {
      return (patientId) => {
        return this.patientProfileImages[patientId] || this.defaultImageUrl;
      };
    }
  },

  mounted() {
    this.fetchDoctorDetails();
    this.fetchPatientProfileImage();
    this.searchPatients();
  }
};
</script>
<style scoped>
.card.widget-profile.pat-widget-profile {
    border: 4px solid rgba(0, 0, 0, 0.2); 
}
.profile-item {
    display: inline-block;
    width: 50%; 
}
.align-right {
    text-align: right;
}
.country-code-select {
  border: 1px solid #ced4da; 
  border-radius: 4px;
  
  font-size: 14px; 
  color: #495057; 
  height: 32px;
  width: 62px;
  margin-left: 20px;
}
</style>