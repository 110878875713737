<template>
  <div class="main-wrapper">
    <layoutheader></layoutheader>
    <breadcrumb :title="title" :text="text" :text1="text1" />

    <div class="content">
      <div class="container">
        <div class="row">
          <div class="col-md-5 col-lg-4 col-xl-3 theiaStickySidebar">
            <div class="stickysidebar">
              <profilesidebar></profilesidebar>
            </div>
          </div>

          <div class="col-md-7 col-lg-8 col-xl-9">
            <div class="card">
              <div class="card-body">
                <div class="col-sm-6" style="padding-top:10px">
                  <h3 class="card-title">Change password</h3>
                </div>
                <div class="row">
                  <div class="col-md-12 col-lg-6">
                    <b-alert v-model="showDismissibleAlert" variant="danger">{{ error }}</b-alert>
                    <b-alert v-model="showDismissibleSuccess" variant="success">{{ error }}</b-alert>
                    <!-- Change Password Form -->
                    <form @submit.prevent="handleUpdatePassword">
                      <div class="mb-3 form-focus position-relative">
                        <label class="focus-label" :class="{ 'has-input': oldpwd }">Old Password</label>
                        <input :type="showPassword ? 'text' : 'password'" class="form-control floating" v-model="oldpwd"
                          maxlength="25" />
                        <button type="button" class="password-toggle" @click="togglePasswordVisibility">
                          <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                      </div>
                      <div class="mb-3 form-focus position-relative">
                        <label class="focus-label" :class="{ 'has-input': newpwd }">New Password</label>
                        <input :type="showPasswordx ? 'text' : 'password'" class="form-control floating"
                          v-model="newpwd" maxlength="25" />
                        <button type="button" class="password-toggle" @click="togglePasswordVisibilityx">
                          <i :class="showPasswordx ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                      </div>
                      <div class="mb-3 form-focus position-relative">
                        <label class="focus-label" :class="{ 'has-input': confirmpwd }">Confirm Password</label>
                        <input :type="showPasswordy ? 'text' : 'password'" class="form-control floating"
                          v-model="confirmpwd" maxlength="25" />
                        <button type="button" class="password-toggle" @click="togglePasswordVisibilityy">
                          <i :class="showPasswordy ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                      </div>
                      <div class="submit-section">
                        <b-button type="submit" class="btn btn-primary submit-btn">
                          Save Changes
                        </b-button>
                      </div>
                    </form>
                    <!-- /Change Password Form -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <indexfooter></indexfooter> -->
     <patientfooter></patientfooter>
  </div>
</template>

<script>
import { updatePassword } from 'aws-amplify/auth';

export default {
  data() {
    return {
      title: "Change Password",
      text: "Home",
      text1: "Change Password",
      oldpwd: '',
      newpwd: '',
      confirmpwd: '',
      error: null,
      showDismissibleAlert: false,
      showDismissibleSuccess: false,
      showPassword: false,
      showPasswordx: false,
      showPasswordy: false,
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    togglePasswordVisibilityx() {
      this.showPasswordx = !this.showPasswordx;
    },
    togglePasswordVisibilityy() {
      this.showPasswordy = !this.showPasswordy;
    },
    showmessage(message, type) {
      this.error = message;
      this.showDismissibleAlert = (type === "danger");
      this.showDismissibleSuccess = !this.showDismissibleAlert;
    },
    min8: function (value) {
      if (value && value.length >= 8) {
        return true;
      } else {
        this.showmessage('Password should have atleast 8 characters.', 'danger');
        return false;
      }
    },
    matchingPasswords: function () {
      if (this.newpwd === this.confirmpwd) {
        return true;
      } else {
        this.showmessage('Passwords do not match.', 'danger');
        return false;
      }
    },
    async handleUpdatePassword() {
      if (!this.min8(this.newpwd)) return;
      if (!this.matchingPasswords()) return;
      this.showDismissibleAlert = false;
      try {
        await updatePassword({ oldPassword: this.oldpwd, newPassword: this.newpwd });
        this.showmessage("Password updated successfully", 'success');
        this.oldpwd = '';
        this.newpwd = '';
        this.confirmpwd = '';
      } catch (err) {
        console.log(err);
        this.showmessage(err.message, 'danger');
      }
    },
  },
};
</script>
<style scoped>
.position-relative {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.password-toggle .fas {
  font-size: 1.2em;
  color: #6c757d;
}
</style>
